import React, { useContext } from 'react';

import './styles.css';
import { Col, Row } from 'react-bootstrap';

import ChangeLanguage from './components/ChangeLanguage';
import UserInfo from './components/UserInfo';
import UserContext from '../../user-context';

const Header = React.memo(() => {
  const { user } = useContext(UserContext);

  return (
    <Row className="header">
      <Col lg={6} md={6} xs={6} className="d-flex align-items-center ps-0">
        <div className="logo-wrapper">
          <img className="logo" src="/assets/logo.png" alt="Logo" />
        </div>
      </Col>

      <Col lg={6} md={6} xs={6} className="d-flex justify-content-end">
        <ChangeLanguage />

        {user && <UserInfo name={`${user.lastname} ${user.firstname}`} />}
      </Col>
    </Row>
  );
});

export default Header;
